<template>
  <div>
    <div class="productDiv">
    <p class="cardTitle">三大核心产品</p>
    <p class="cardSecondTitle">信息聚合、财税解决方案+灵活用工平台定制化</p>
    <ul class="serviceUl" >
      <!-- style="background-image:url('/img/serviceBg.png');" -->
      <li>
        <div class="serviceCard"  @mousemove=" serverceindex = 1" @mouseout="serverceindex = 0" :class="serverceindex==1?'hoverCardClass':'' ">
           <i class="iconfont"  @mousemove=" serverceindex = 1" @mouseout="serverceindex = 0" :class="serverceindex==1?'hoverClass':'' ">&#xe61b;</i>
           <p class="serviceTitle">灵活用工解决方案</p>
           <p>解决发佣问题：佣金代发即时到账</p>
           <p>解决税务合规：多地税局授权委托代征，开具税率6%发票</p>
           <div class="tip"></div>
        </div>
      </li>
        <li>
        <div class="serviceCard" @mousemove=" serverceindex = 2" @mouseout="serverceindex = 0" :class="serverceindex==2?'hoverCardClass':'' ">
           <i class="iconfont"  @mousemove=" serverceindex = 2" @mouseout="serverceindex = 0" :class="serverceindex==2?'hoverClass':'' ">&#xe8d2;</i>
           <p class="serviceTitle">智能saas系统</p>
           <p>解决没有技术但想搭建灵活用工系统难题</p>
           <p>多租户数据隔离，确保数据安全</p>
           <div class="tip"></div>
        </div>
      </li>
        <li>
        <div class="serviceCard" @mousemove=" serverceindex = 3" @mouseout="serverceindex = 0" :class="serverceindex==3?'hoverCardClass':'' ">
           <i class="iconfont"  @mousemove=" serverceindex = 3" @mouseout="serverceindex = 0" :class="serverceindex==3?'hoverClass':'' ">&#xe669;</i>
           <p class="serviceTitle">开放API接口</p>
           <p>与企业和平台实现跨平台对接</p>
           <div class="tip"></div>
        </div>
      </li>
    </ul>
    </div>
    <div class="serviceStep">
      <h3 class="title">服务流程</h3>
     <div>
       <ul class="imgUl">
          <li><i class="iconfont">&#xe625;</i></li>
          <li><i class="iconfont">&#xe627;</i></li>
          <li><i class="iconfont">&#xe70e;</i></li>
          <li><i class="iconfont">&#xe638;</i></li>
          <li><i class="iconfont">&#xe941;</i></li>
       </ul>
       <ul class="line">
          <li><i></i></li>
          <li><i></i></li>
          <li><i></i></li>
          <li><i></i></li>
          <li><i></i></li>
       </ul>
       <ul class="textUl">
          <li>在线/电话咨询</li>
          <li>明确需求</li>
          <li>解决指定方案</li>
          <li>签署合作协议</li>
          <li>进行服务</li>
       </ul>
     </div>
    </div>
    <div>
    <p class="cardTitle">服务特色</p>
    <ul class="goodness">
      <li class="goodnessCard"  @mousemove=" serverceindex = 1" @mouseout="serverceindex = 0" :class="serverceindex==1?'servicehoverClass':'' ">
           <i class="iconfont">&#xe603;</i>
           <p class="goodnessTitle">业务合规</p>
           <p class="goodnessSecondTitle">保障四流合一与完整性</p>
      </li>
      <li class="goodnessCard"  @mousemove=" serverceindex = 2" @mouseout="serverceindex = 0" :class="serverceindex==2?'servicehoverClass':'' ">
           <i class="iconfont">&#xe637;</i>
           <p class="goodnessTitle">实时到账</p>
           <p class="goodnessSecondTitle">多家银行战略合作，佣金发放极速到账</p>
      </li>
      <li class="goodnessCard"  @mousemove=" serverceindex = 3" @mouseout="serverceindex = 0" :class="serverceindex==3?'servicehoverClass':'' ">
           <i class="iconfont">&#xe641;</i>
           <p class="goodnessTitle">运营支持</p>
           <p class="goodnessSecondTitle">7*24小时跟进服务，提供财税及业务咨询</p>
      </li>
      <li class="goodnessCard"  @mousemove=" serverceindex = 4" @mouseout="serverceindex = 0" :class="serverceindex==4?'servicehoverClass':'' ">
           <i class="iconfont">&#xe66b;</i>
           <p class="goodnessTitle">多渠道支付</p>
           <p class="goodnessSecondTitle">支持微信、银行卡、支付宝多渠道结算</p>
      </li>
    </ul>
  </div>
  </div>
</template>

<script>
export default {
  name: 'product',
  props: {
    msg: String
  },
  data(){
    return{
      serverceindex:0,
      industryindex:1,
      carouselHeight:'450px',
      industryWidth:'240px',
         imgArr: [
        {
          img: "/img/banner1.jpg",
          content: "UP科技，您的财税解决专家",
          index: 1,
        },
        {
          img: "/img/banner2.jpg",
          content: "为企业提供高效、专业、低费、合规的共享经济服务",
          index: 2,
        },
      ],
      industryArr:[
        {img:'/img/industry01_s.png',imgl:'/img/industry01.png',title1:'批发零售',title2:'代理商、推广服务等',index:1},
        {img:'/img/industry02_s.png',imgl:'/img/industry02.png',title1:'电商平台',title2:'供应链、直播等',index:2},
        {img:'/img/industry03_s.png',imgl:'/img/industry03.png',title1:'物流配送',title2:'司机、骑手、装卸服务等',index:3},
        {img:'/img/industry04_s.png',imgl:'/img/industry04.png',title1:'软件开发',title2:'技术工程师、信息技术服务等',index:4}
      ],

    }
  },
  mounted(){
  if(window.screen.width<=768){
     this.carouselHeight = '300px';
  }else if(window.screen.width<1025 && window.screen.width>768){
     this.carouselHeight = '400px';
  }else{
     this.carouselHeight = '450px';
  }

  },
  methods:{
    industryOver(item){
        item.img = item.imgl;
      this.industryWidth= '460px';
    }
  }
}
</script>

<style scoped>
.el-carousel__item{
      display: flex;
    justify-content: center;
    align-items: center;
}
  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }
  .atention{
  font-size: 18px;
      font-weight: bold;
  position:absolute;
  bottom:35px;
  left:150px;
  }
.productDiv{
      width: 1200px;
      margin: 0 auto;
      color:#fff;
}

.serviceStep{
    background: #F5F7F9;
        padding:60px 0 80px 0;
    text-align: center;
}
.serviceStep .title{
     margin-bottom: 50px;
    font-size: 40px;
    color: #333333;
    font-weight: bold;
}
.serviceStep img{
      width: 1200px;
    height: auto;
}
.serviceStep .imgUl,.serviceStep .textUl{
      width: 1200px;
      margin: 0 auto;
    height: auto;
    display: flex;
}
.serviceStep .imgUl li{
  flex: 1;
}
.serviceStep .imgUl li i{
  font-size: 60px;
  color:#3EB2FF;
}
.line{
        position: relative;
    width: 1200px;
    height: 30px;
    border-bottom: 2px dashed #3EB2FF;
    margin: 0 auto;
    display: flex;
}
.line li{
    flex: 1;
}
.line li i{
      display: inline-block;
    width: 8px;
    bottom: -5px;
    position: absolute;
    height: 8px;
    /* line-height: 29px; */
    border-radius: 50%;
    background: #3EB2FF;
}
 .serviceStep .textUl li {
  flex: 1;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  line-height: 85px;
}
 .serviceStep .textUl li:first-child ,  .serviceStep .textUl li:nth-child(3),  .serviceStep .textUl li:nth-child(4) {
 flex: 1.2;
}
  .serviceUl{
    list-style: none;
    box-sizing: border-box;
    width: 1200px;
    margin: 60px auto;
    display: flex;
    justify-content: center;
  }
   .serviceUl li{
    list-style: none;
    box-sizing: border-box;
    flex: 1;
  }
.serviceCard{
       display: flex;
    align-items: center;
    flex-direction: column;
    /* width: 400px; */
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    height: 400px;
    border-radius: 25px;
    justify-content: center;
    border: 2px solid #3EB2FF;
    box-shadow: -4px -4px 20px 8px rgb(28 88 159 / 16%);
}
.serviceUl li:first-child{
  margin-right: 65px;
}
.serviceUl li:nth-child(2){
  margin-right: 65px;
}
.serviceUl li:last-child{
  margin-right: 0!important;
}
.serviceCard i{
      font-size: 100px;
    display: inline-block;
    border: 4px solid #3EB2FF;
   width: 44%;
    height: 44%;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #3EB2FF;
}
.serviceCard p{
        color: #555;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 32px;
    height: 45px;
    /* margin-bottom: 10px; */
    text-align: center;
}
.serviceCard p:first-child{
    margin-bottom: 10px;
}
.serviceCard .serviceTitle{
        font-weight: bold;
    color: #555;
    font-size: 21px;
    margin: 26px 0 15px 0;
    letter-spacing: 1.5px;
}
.tip{
      width: 80px;
    height: 4px;
    background-color: #3EB2FF;
    margin: 40px auto 0;
}
.hoverCardClass{
  background:#3EB2FF;
  border: none;
  color: #fff;
}
 .hoverCardClass p,.hoverCardClass .serviceTitle{
  color: #fff;
} 
.hoverCardClass .tip{
   background:#fff;
}
.hoverCardClass i{
   background:#fff;
  border: #fff;
  color: #3EB2FF;
}
.industryUl{
    display: flex;
    padding: 55px 200px;

}
.industryUl{
       margin: 55px auto;
    width: 1080px;
}
.industryCard{
height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.mc{
     width: 100%;
    height: 100%;
    writing-mode: tb;
    background-color: rgba(0,24,71,.5);
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: middle;
    line-height: 240px;
    text-align: center;
    z-index: 99;
}
.mcCard{
      width: 210px;
    height: 210px;
    background-color: rgba(0,24,71,.5);
    border-radius: 50%;
    text-align: center;
    line-height: 210px;
}
.industryTitle1{
  display: inline-block;
   color: #fff;
    font-size: 20px;
}
.goodness{
      padding: 20px 0 20px 0;
    margin: 0 auto 55px auto;
    width: 1200px;
    position: relative;
    height: auto;
    display: flex;
}
.goodnessCard{
        flex: 1;
    padding: 30px 0;
    margin-right: 30px;
    text-align: center;
    border-radius: 20px;
    background: rgb(245, 247, 249);
}

.goodnessCard:last-child{
      margin: 0;
}
.goodnessCard i{
    font-size: 80px;
    color: #3EB2FF;
    line-height: 100px;
}
.goodnessCard .goodnessSecondTitle{
     padding: 22px 40px;
    text-align: center;
    font-size: 16px;
}
.goodnessTitle{
font-size: 24px;
    margin-top: 28px;
}
.servicehoverClass{
  background:#fff ;
      box-shadow: -3px -4px 11px 8px #EBF0F6;
}
  /* 笔记本 电脑  */
 @media (min-width: 1031px) {
  .el-carousel__container{
  height: 450px;
  }
  .el-carousel__item h3 {
    font-size: 38px;
  }
}

/*  pad pro 1024(pc一样)*/
@media(min-width:768px) and (max-width:1030px){
   
  .el-carousel__container{
  height: 400px;
  }
  .el-carousel__item h3 {
    font-size: 25px;
  }
  .serviceUl{
    width: 100%;
  }
  .productDiv{
    width: 95%;
  }
  .serviceUl li:first-child , .serviceUl li:nth-child(2) {
    margin-right: 30px;
}
  .serviceStep .imgUl,.serviceStep .textUl{
      width:100%;
      margin: 0 auto;
    height: auto;
    display: flex;
}
.line{
      width:100%;
}
  .serviceCard {
    height: 350px;
  }
  .serviceCard i{
    font-size: 50px;
    width: 80px;
    height: 80px;
  }
.serviceCard .serviceTitle{
    font-size: 19px;
    margin: 17px 0 0px 0;
}
.serviceCard p{
      font-size: 14px;
    letter-spacing: 0px;
    line-height: 28px;
    height: 45px;
}
.serviceCard p:nth-child(3){
  margin-bottom: 18px;
}
.tip{
    margin: 25px auto 0;
}
  .goodness{
    width: 86%;
  }
.goodnessCard .goodnessSecondTitle{
      padding: 22px 22px;
      line-height: 32px;
}


}

/* 超小屏幕（手机，小于 768px） */
@media (max-width: 768px) { 

  .el-carousel__container{
  height: 300px;
  }
  .el-carousel__item h3 {
    font-size: 20px;
        line-height: 45px;
    padding: 30px;
  }
  .atention{
  font-size: 13px;
  bottom:25px;
  left:30px;
  }
  .serviceUl{
    width: 90%;
    display: block;
    margin: 10px auto;
  }
  .productDiv{
    width: 90%;
    margin: 0 auto 20px auto;
  }
  .serviceUl li:first-child , .serviceUl li:nth-child(2) {
    margin-right: 0;
        margin-bottom: 20px;
}
   .serviceStep .imgUl,.serviceStep .textUl{
      width:100%;
      margin: 0 auto;
    height: auto;
    display: flex;
}
.serviceStep .imgUl li i{
  font-size: 26px;
}
.line{
      width:100%;
}
.serviceStep .textUl li{
   font-size: 12px;
}
.serviceStep{
    padding: 30px 0 30px 0;
}
.serviceStep .title{
      line-height: 45px;
    height: 45px;
    text-align: center;
   margin: 0px 0 30px 0!important;
    font-size: 22px;
    color: #555;
    font-weight: bold;
}
  .serviceCard {
    height: auto;
  }
  .serviceCard i{
    font-size: 50px;
    width: 80px;
    height: 80px;
  }
.serviceCard .serviceTitle{
    font-size: 19px;
    margin: 17px 0 0px 0;
}
.serviceCard p{
      font-size: 14px;
    letter-spacing: 0px;
    line-height: 28px;
    height: 45px;
    
}
.serviceCard p:nth-child(3){
      margin-bottom: 15px;
    height: auto;
}
.tip{
    margin: 25px auto 0;
}
  .goodness{
    width: 80%;
        display: block;
            margin: 0 auto 30px auto;
  }

 .goodnessCard{
        margin-right: 0;
        padding: 20px 0;
    margin-bottom: 20px;
  }
  .goodnessTitle{
    font-size: 24px;
    margin-top: 15px;
}
.goodnessCard .goodnessSecondTitle{
      padding: 22px 22px;
      line-height: 32px;
}
}
</style>
